import { useState, useEffect } from 'preact/hooks';
import ParameterTable from './APIDetailsParametersChilds/ParameterTable.tsx';
import { Parameter } from './APIDetailsParametersChilds/Types.ts';

interface APIDetailsParametersProps {
  parameters?: Parameter[];
  selectedPath: string | null;
  selectedMethod: string | null;
  onParameterChange: (selectedParameters: any[]) => void;
}

export default function APIDetailsParameters({ parameters, selectedPath, selectedMethod, onParameterChange }: APIDetailsParametersProps) {
  const [selectedParameters, setSelectedParameters] = useState<any[]>([]);

  useEffect(() => {
    // Bei Änderung von selectedPath oder selectedMethod: setze die Parameter zurück
    const defaultParameters = parameters?.map((param) => ({
      name: param.name,
      value: "",
      in: param.in
    })) || [];

    setSelectedParameters(defaultParameters);
    onParameterChange(defaultParameters);
  }, [selectedPath, selectedMethod, parameters]);

  const handleParameterSelection = (parameter: Parameter, selectedValue: string) => {
    const updatedParameters = [...selectedParameters];
    const paramIndex = updatedParameters.findIndex((param) => param.name === parameter.name);

    if (paramIndex >= 0) {
      updatedParameters[paramIndex].value = selectedValue;
    } else {
      updatedParameters.push({ name: parameter.name, value: selectedValue, in: parameter.in });
    }

    setSelectedParameters(updatedParameters);
    onParameterChange(updatedParameters);
  };

  return (
    <div class="mt-4">
      <h4 class="font-semibold text-lg md:text-xl text-slate-950 mb-2 ">Parameters</h4>
      <ParameterTable parameters={parameters} selectedParameters={selectedParameters} onParameterSelection={handleParameterSelection} />
    </div>
  );
}
